.color-english {
  color: #40C2CC; }

.color-spanish {
  color: #A1CD3A; }

.color-chinese {
  color: #D83B7F; }

.color-filipino {
  color: #9479B7; }

#translator {
  background-color: #000; }

#app.world #translator {
  width: 100%;
  height: 7.03125vw; }
  #app.world #translator button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    height: 7.03125vw;
    float: right;
    background-color: #000;
    font-size: 3.90625vw;
    font-weight: normal;
    letter-spacing: 0.10937vw;
    color: #fff;
    font-family: 'W-Medium', 'SourceHanSansCN-Normal'; }
    #app.world #translator button span {
      vertical-align: middle; }
    #app.world #translator button.language-select {
      float: left; }
      #app.world #translator button.language-select span.select-tab {
        margin-left: 5.625vw;
        text-align: left;
        padding-top: 0;
        font-family: 'W-Book', 'SourceHanSansCN-Normal';
        font-size: 3.90625vw;
        display: inline-block;
        width: 23.4375vw;
        background-image: url("../img/select-arrow-down.png");
        background-repeat: no-repeat;
        background-position-x: 19.0625vw;
        background-position-y: 2.5vw;
        background-size: 10%; }
        #app.world #translator button.language-select span.select-tab.open {
          background-image: url("../img/select-arrow-up.png"); }
      #app.world #translator button.language-select span.active-language {
        margin-left: 2.8125vw;
        font-size: 3.90625vw; }
    #app.world #translator button.credits {
      color: #d6d6d6;
      border-left: 1px solid #fff;
      padding-left: 10vw;
      padding-right: 10vw; }
  #app.world #translator #dropdown {
    position: relative; }
  #app.world #translator .dropdown-content {
    visibility: hidden;
    position: absolute;
    top: 7.03125vw;
    left: 21.875vw;
    z-index: 1; }
    #app.world #translator .dropdown-content.open {
      visibility: visible; }
    #app.world #translator .dropdown-content button {
      float: none;
      display: block;
      width: 46.25vw;
      height: 11.5625vw;
      padding-left: 10vw;
      text-align: left;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      border-bottom: none; }
      #app.world #translator .dropdown-content button:last-child {
        border-bottom: 1px solid #fff; }
      #app.world #translator .dropdown-content button#language-select-english:hover {
        color: #40C2CC; }
      #app.world #translator .dropdown-content button#language-select-spanish:hover {
        color: #A1CD3A; }
      #app.world #translator .dropdown-content button#language-select-chinese:hover {
        color: #D83B7F; }
      #app.world #translator .dropdown-content button#language-select-filipino:hover {
        color: #9479B7; }

#app.exhibit #translator {
  width: 1080px;
  height: 50px; }
  #app.exhibit #translator button.credits {
    width: 129px; }
  #app.exhibit #translator button {
    margin: 0;
    padding: 0;
    border: none;
    border-left: 1px solid #fff;
    outline: none;
    height: 50px;
    width: 205px;
    float: right;
    background-color: #000;
    font-size: 25px;
    line-height: 30px;
    font-weight: 200;
    letter-spacing: 0.7px;
    color: #d6d6d6; }
    #app.exhibit #translator button span {
      font-family: 'W-Medium', 'SourceHanSansCN-Medium';
      font-size: 25px;
      display: inline-block;
      padding-top: 2px;
      color: #d6d6d6;
      /*.credits & {
        color: $credits-dark-gray !important;
      }*/ }
      #app.exhibit #translator button span:active {
        opacity: 0.7; }
    #app.exhibit #translator button.credits {
      border-right: none !important; }
      #app.exhibit #translator button.credits span {
        color: #919191;
        font-family: 'W-Semibold' !important;
        font-size: 18px;
        line-height: 22px; }
      #app.exhibit #translator button.credits.active span {
        font-family: 'W-Semibold';
        color: #919191; }
    #app.exhibit #translator button.active-english span {
      color: #40C2CC;
      font-weight: bold; }
    #app.exhibit #translator button.active-spanish span {
      color: #A1CD3A;
      font-weight: bold; }
    #app.exhibit #translator button.active-chinese span {
      color: #D83B7F;
      font-weight: bold; }
    #app.exhibit #translator button.active-filipino span {
      color: #9479B7;
      font-weight: bold; }
