#slideshow .carousel-item .carousel-caption {
  opacity: 0; }

#slideshow .active .carousel-caption {
  opacity: 1; }

#slideshow .carousel-caption {
  transition: opacity .25s ease-in-out; }

#slideshow .carousel-indicators {
  display: none; }

#slideshow .carousel-control-prev,
#slideshow .carousel-control-next {
  opacity: 1 !important; }

#slideshow .carousel-control-prev-icon {
  background-image: url("../img/carousel-left.svg"); }

#slideshow .carousel-control-next-icon {
  background-image: url("../img/carousel-right.svg"); }

#slideshow .visually-hidden {
  visibility: hidden !important; }

#app.world #slideshow .slideshow-slide-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82.5vw;
  height: 127.5vw;
  margin-left: 5.625vw;
  touch-action: pan-y !important; }

#app.world #slideshow .slideshow-slide-image div.video,
#app.world #slideshow .slideshow-slide-image div.image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82.5vw;
  height: 127.5vw; }

#app.world #slideshow .slideshow-slide-image div img {
  max-width: 82.5vw;
  max-height: 127.5vw; }

#app.world #slideshow .slideshow-slide-image video {
  max-width: 82.5vw;
  max-height: 127.5vw; }

#app.world #slideshow .carousel-control-prev,
#app.world #slideshow .carousel-control-next {
  width: 3.75vw; }

#app.world #slideshow .carousel-control-prev-icon {
  width: 5.9375vw;
  height: 5.9375vw; }

#app.world #slideshow .carousel-control-next-icon {
  width: 5.9375vw;
  height: 5.9375vw; }

#app.exhibit #slideshow .slideshow-slide-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 912px;
  height: 1435px;
  margin-left: 50px;
  touch-action: pan-y !important; }

#app.exhibit #slideshow .slideshow-slide-image div.video,
#app.exhibit #slideshow .slideshow-slide-image div.image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 912px;
  height: 1435px; }

#app.exhibit #slideshow .slideshow-slide-image div img {
  max-width: 912px;
  max-height: 1435px; }

#app.exhibit #slideshow .slideshow-slide-image video {
  max-width: 912px;
  max-height: 1435px; }

#app.exhibit #slideshow .carousel-control-prev,
#app.exhibit #slideshow .carousel-control-next {
  width: 34px; }

#app.exhibit #slideshow .carousel-control-prev-icon {
  width: 50px;
  height: 50px; }

#app.exhibit #slideshow .carousel-control-next-icon {
  width: 50px;
  height: 50px; }
