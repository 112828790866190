@font-face {
  font-family: 'W-Medium';
  src: url("../fonts/W-Medium-Pro.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'W-Semibold';
  src: url("../fonts/W-Semibold-Pro.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'W-Cond-Medium';
  src: url("../fonts/W-Cond-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'W-Cond-Semibold';
  src: url("../fonts/W-Cond-Semibold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'W-Narrow-Book';
  src: url("../fonts/W-Narr-Book-Old.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'W-Book';
  src: url("../fonts/W-Book-Pro.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'W-Narrow-Semibold';
  src: url("../fonts/W-Narr-Semibold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceHanSansCN-Normal';
  src: url("../fonts/SourceHanSansCN-Normal.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceHanSansCN-Medium';
  src: url("../fonts/SourceHanSansCN-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceHanSansCN-Bold';
  src: url("../fonts/SourceHanSansCN-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

body {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  margin: 0;
  padding: 0;
  cursor: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-font-feature-settings: "ss17" 1, "liga" 1;
  -moz-font-feature-settings: "ss17" 1, "liga" 1;
  -ms-font-feature-settings: "ss17" 1, "liga" 1;
  font-feature-settings: "ss17" 1, "liga" 1;
  overflow-x: hidden !important; }
  body h1, body h2, body h3, body h4, body h5, body h6, body p, body ul, body li, body button, body footer, body img, body caption, body span {
    margin: 0;
    padding: 0;
    font-weight: normal; }
  body button, body footer, body img, body caption {
    border: none;
    outline: none; }
  body ul, body li {
    list-style: none;
    display: block; }
  body sup, body sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em; }
  body sub {
    top: 0.4em; }
  body em {
    font-style: italic; }
  body img {
    border: none; }

/*::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent;
}*/
