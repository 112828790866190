#case-menu h1 {
  font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }

#case-menu div.case {
  border-top: 1px solid #333333; }
  #case-menu div.case:last-of-type {
    border-bottom: 1px solid #333333; }

#case-menu div.case > div {
  font-family: 'W-Book', 'SourceHanSansCN-Normal'; }

#app.world #case-menu {
  width: 100%;
  height: 100vh; }
  #app.world #case-menu h1 {
    font-size: 6.25vw;
    line-height: 7.5vw;
    padding-top: 13.90625vw;
    padding-bottom: 6.875vw;
    margin-left: 5.625vw;
    margin-right: 5.625vw; }
  #app.world #case-menu .content-stacked {
    margin-left: 3.125vw;
    margin-right: 3.125vw; }
    #app.world #case-menu .content-stacked div.case > div {
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      padding-top: 6.875vw;
      padding-bottom: 6.875vw;
      font-size: 6.25vw;
      line-height: 7.5vw;
      margin-left: 2.5vw;
      margin-right: 2.5vw;
      color: #43C2CC; }
