#app.exhibit #attract {
  width: 1080px;
  height: 1920px; }
  #app.exhibit #attract #attract-backplate {
    position: absolute;
    width: 1080px;
    height: 1920px;
    z-index: 99; }
  #app.exhibit #attract #attract-mask-top {
    position: absolute;
    z-index: 98;
    width: 1080px;
    background-color: #000;
    opacity: 0; }
  #app.exhibit #attract #attract-mask-top {
    height: 50px;
    top: 0; }
  #app.exhibit #attract #attract-mask-top.highlight {
    animation: fade-up-to-full 2s forwards; }
  #app.exhibit #attract #case-cta-see-all {
    position: absolute;
    z-index: 100;
    pointer-events: none;
    font-family: 'W-Book', 'SourceHanSansCN-Normal';
    text-align: center;
    bottom: 70px;
    right: 70px;
    border-radius: 40px;
    border: 4px solid #43C2CC;
    font-size: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 400px; }
  #app.exhibit #attract #case-cta-see-all.fadedown {
    animation: fade-down-to-full 2s forwards; }
  #app.exhibit #attract h1 {
    position: absolute;
    z-index: 100;
    pointer-events: none;
    font-family: 'W-Semibold', 'SourceHanSansCN-Medium';
    font-size: 60px;
    line-height: 72px;
    padding-top: 110px;
    padding-left: 40px;
    padding-right: 70px; }
  #app.exhibit #attract h1.fadedown {
    animation: fade-down-to-full 1s forwards; }
  #app.exhibit #attract h1.highlight {
    animation: fade-up-to-full 1s forwards; }
  #app.exhibit #attract #attract-groups-images {
    width: 1080px;
    height: 1920px;
    position: absolute;
    top: 0;
    z-index: 101;
    pointer-events: none; }
    #app.exhibit #attract #attract-groups-images img {
      position: absolute;
      z-index: 101;
      pointer-events: none; }
    #app.exhibit #attract #attract-groups-images img.fadedown {
      animation: fade-down-to-partial 1s forwards; }
    #app.exhibit #attract #attract-groups-images img.highlight {
      animation: fade-up-from-partial 1s; }
  #app.exhibit #attract #attract-groups-buttons {
    width: 1080px;
    height: 1920px;
    position: absolute;
    top: 0;
    z-index: 100;
    pointer-events: none; }
    #app.exhibit #attract #attract-groups-buttons div {
      pointer-events: auto !important;
      position: absolute;
      z-index: 100;
      background-color: #43C2CC;
      border-radius: 50%;
      display: inline-block; }
    #app.exhibit #attract #attract-groups-buttons div.fadedown {
      animation: fade-down-to-partial 1s forwards; }
    #app.exhibit #attract #attract-groups-buttons div.highlight {
      animation: fade-up-from-partial 1s; }
  #app.exhibit #attract #attract-groups-names {
    width: 1080px;
    height: 1920px;
    position: absolute;
    top: 0;
    z-index: 102;
    pointer-events: none; }
    #app.exhibit #attract #attract-groups-names .attract-group-name {
      pointer-events: auto !important;
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      font-size: 52px;
      line-height: 62px;
      position: absolute;
      z-index: 99;
      opacity: 0;
      min-width: 216px;
      padding-bottom: 36px; }
      #app.exhibit #attract #attract-groups-names .attract-group-name .attract-group-name-dna-bar {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 20px;
        max-height: 20px;
        background-image: url("../img/dna-bar-group-name.png");
        background-repeat: no-repeat;
        background-position-x: -400px;
        background-position-y: 0;
        opacity: 0;
        overflow: hidden; }
      #app.exhibit #attract #attract-groups-names .attract-group-name .attract-group-name-dna-bar.grow {
        animation: dna-group-name 1.5s .5s forwards;
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    #app.exhibit #attract #attract-groups-names .attract-group-name.fadedown {
      animation: fade-down-to-full 1s forwards; }
    #app.exhibit #attract #attract-groups-names .attract-group-name.highlight {
      animation: fade-up-to-full 1s forwards; }
  #app.exhibit #attract #attract-groups-images.fadedown,
  #app.exhibit #attract #attract-groups-buttons.fadedown,
  #app.exhibit #attract #attract-groups-names.fadedown {
    animation: fade-down-to-full 1s forwards; }
  #app.exhibit #attract #attract-groups-images.highlight,
  #app.exhibit #attract #attract-groups-buttons.highlight,
  #app.exhibit #attract #attract-groups-names.highlight {
    animation: fade-up-to-full 1s forwards; }
  #app.exhibit #attract #attract-case-name-container {
    position: absolute;
    z-index: 100;
    pointer-events: none;
    bottom: 930px;
    opacity: 0;
    width: 840px;
    margin-left: 120px;
    margin-right: 120px;
    text-align: center;
    padding-top: 160px; }
    #app.exhibit #attract #attract-case-name-container #attract-case-name-dna-bar-top-a {
      position: absolute;
      top: 0;
      right: -120px;
      width: 0;
      height: 40px;
      background-image: url("../img/dna-bar-case-name-top.png");
      background-repeat: no-repeat;
      background-position-x: -700px;
      background-position-y: 0;
      opacity: 1;
      overflow: hidden; }
    #app.exhibit #attract #attract-case-name-container #attract-case-name-dna-bar-top-a.grow {
      animation: dna-case-name-top-a 3.5s 3.5s forwards; }
    #app.exhibit #attract #attract-case-name-container #attract-case-name-dna-bar-top-b {
      position: absolute;
      top: 62px;
      right: -120px;
      width: 0;
      height: 40px;
      background-image: url("../img/dna-bar-case-name-top.png");
      background-repeat: no-repeat;
      background-position-x: -700px;
      background-position-y: 0;
      opacity: 1;
      overflow: hidden; }
    #app.exhibit #attract #attract-case-name-container #attract-case-name-dna-bar-top-b.grow {
      animation: dna-case-name-top-b 3s 4s forwards; }
    #app.exhibit #attract #attract-case-name-container #attract-case-name {
      text-align: left;
      letter-spacing: 1.3px;
      display: inline;
      font-size: 200px;
      line-height: 200px;
      font-family: 'W-Semibold';
      color: #43C2CC; }
    #app.exhibit #attract #attract-case-name-container #attract-case-name.color-trans {
      animation: cyan-white-cyan 10s forwards; }
  #app.exhibit #attract #attract-case-name-container.come-and-go {
    animation: come-and-go-10 10s .5s forwards; }
  #app.exhibit #attract #attract-case-name-translations {
    position: absolute;
    z-index: 100;
    pointer-events: none;
    top: 1115px;
    left: 485px;
    width: 475px;
    opacity: 0; }
    #app.exhibit #attract #attract-case-name-translations div {
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      font-size: 58px;
      line-height: 71px;
      padding-bottom: 30px; }
    #app.exhibit #attract #attract-case-name-translations #attract-case-name-spanish {
      color: #A1CD3A; }
    #app.exhibit #attract #attract-case-name-translations #attract-case-name-spanish.color-trans {
      animation: green-white 1s 6s forwards; }
    #app.exhibit #attract #attract-case-name-translations #attract-case-name-chinese {
      color: #D83B7F; }
    #app.exhibit #attract #attract-case-name-translations #attract-case-name-chinese.color-trans {
      animation: red-white 1s 6s forwards; }
    #app.exhibit #attract #attract-case-name-translations #attract-case-name-filipino {
      color: #9479B7; }
    #app.exhibit #attract #attract-case-name-translations #attract-case-name-filipino.color-trans {
      animation: purple-white 1s 6s forwards; }
  #app.exhibit #attract #attract-case-name-translations.come-and-go {
    animation: come-and-go-7 7s 2s forwards; }
  #app.exhibit #attract #attract-case-name-dna-bar-bottom {
    position: absolute;
    bottom: 835px;
    width: 690px;
    height: 65px;
    background-image: url("../img/dna-bar-case-name-bottom.png");
    background-repeat: no-repeat;
    background-position-x: -800px;
    background-position-y: 0;
    opacity: 0;
    overflow: hidden; }
  #app.exhibit #attract #attract-case-name-dna-bar-bottom.grow {
    animation: dna-case-name-bottom 5s 2s forwards;
    animation-timing-function: ease-in-out; }

@keyframes fade-down-to-partial {
  0% {
    filter: brightness(1); }
  100% {
    filter: brightness(0.3); } }

@keyframes fade-up-from-partial {
  0% {
    filter: brightness(0.3); }
  100% {
    filter: brightness(1); } }

@keyframes fade-up-to-full {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-down-to-full {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes come-and-go-10 {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes come-and-go-7 {
  0% {
    opacity: 0; }
  14% {
    opacity: 1; }
  86% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes cyan-white-cyan {
  0% {
    color: #43C2CC; }
  25% {
    color: #43C2CC; }
  35% {
    color: #fff; }
  45% {
    color: #fff; }
  60% {
    color: #fff; }
  70% {
    color: #43C2CC; }
  100% {
    color: #43C2CC; } }

@keyframes green-white {
  0% {
    color: #A1CD3A; }
  100% {
    color: #fff; } }

@keyframes red-white {
  0% {
    color: #D83B7F; }
  100% {
    color: #fff; } }

@keyframes purple-white {
  0% {
    color: #9479B7; }
  100% {
    color: #fff; } }

@keyframes dna-group-name {
  0% {
    background-position-x: -400px;
    opacity: 0;
    width: 0; }
  100% {
    background-position-x: -52px;
    opacity: 1;
    width: 216px; } }

@keyframes dna-case-name-bottom {
  0% {
    opacity: 0;
    background-position-x: -900px;
    width: 690px; }
  20% {
    opacity: 1;
    background-position-x: -900px;
    width: 690px; }
  40% {
    opacity: 1;
    background-position-x: -750px;
    width: 430px; }
  50% {
    width: 430px;
    background-position-x: -750px; }
  75% {
    background-position-x: -1500px;
    width: 690px; }
  100% {
    opacity: 1;
    background-position-x: -2750px;
    width: 0px; } }

@keyframes dna-case-name-top-a {
  0% {
    width: 0;
    background-position-x: -700px; }
  25% {
    width: 445px;
    background-position-x: -500px; }
  75% {
    width: 445px;
    background-position-x: -500px; }
  100% {
    width: 0px;
    background-position-x: -100px; } }

@keyframes dna-case-name-top-b {
  0% {
    width: 0;
    background-position-x: -700px; }
  33% {
    width: 275px;
    background-position-x: -500px; }
  66% {
    width: 275px;
    background-position-x: -500px; }
  100% {
    width: 0px;
    background-position-x: -100px; } }
