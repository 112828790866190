#app {
  max-width: 100% !important;
  overflow-x: hidden !important; }
  #app #container-loader {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 22;
    display: block; }
  #app #container-error {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 21;
    display: none; }
  #app #container-attract {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 20;
    display: none; }
  #app #container-translator {
    width: 100%;
    position: fixed;
    z-index: 19;
    display: none; }
  #app #container-credits {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    z-index: 18;
    display: none; }
  #app #container-case {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 17;
    display: none; }
  #app #container-group {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 16;
    display: none; }
  #app #container-group #btn-back,
  #app #container-specimen #btn-back {
    position: absolute;
    z-index: 999;
    background-image: url("../img/btn-back.svg");
    background-repeat: no-repeat;
    background-position: 0 0; }
    #app #container-group #btn-back:active,
    #app #container-specimen #btn-back:active {
      opacity: 0.7; }
  #app #container-group-menu {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 15;
    display: none; }
  #app #container-case-menu {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 14;
    display: none; }
  #app #container-group-menu #btn-close,
  #app #container-case-menu #btn-close,
  #app #container-credits #btn-close {
    position: absolute;
    z-index: 999;
    background-image: url("../img/btn-close.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #000; }
    #app #container-group-menu #btn-close:active,
    #app #container-case-menu #btn-close:active,
    #app #container-credits #btn-close:active {
      opacity: 0.7; }
  #app #container-specimen {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 13;
    display: none; }
  #app .hide {
    display: none !important; }
  #app .show {
    display: block !important; }

#app.world {
  width: 100%;
  height: 100%;
  cursor: pointer !important; }
  #app.world #credits {
    height: 100vh !important;
    top: 17.1875vw; }
  #app.world #container-translator {
    border-bottom: 1px solid #fff; }
  #app.world #container-case {
    min-height: 175vw;
    max-height: 175vw;
    height: 100vh;
    max-width: 100% !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important; }
  #app.world #container-group #group #btn-back,
  #app.world #container-group #specimen #btn-back,
  #app.world #container-specimen #group #btn-back,
  #app.world #container-specimen #specimen #btn-back {
    top: 10.15625vw;
    left: 3.125vw;
    width: 7.8125vw;
    height: 7.8125vw; }
  #app.world #container-group-menu #group-menu #btn-close,
  #app.world #container-group-menu #case-menu #btn-close,
  #app.world #container-group-menu #credits #btn-close,
  #app.world #container-case-menu #group-menu #btn-close,
  #app.world #container-case-menu #case-menu #btn-close,
  #app.world #container-case-menu #credits #btn-close,
  #app.world #container-credits #group-menu #btn-close,
  #app.world #container-credits #case-menu #btn-close,
  #app.world #container-credits #credits #btn-close {
    top: 10.15625vw;
    right: 3.125vw;
    width: 7.8125vw;
    height: 7.8125vw; }

#app.exhibit {
  width: 1080px;
  height: 1920px;
  overflow-y: hidden !important; }
  #app.exhibit #credits {
    top: 110px; }
  #app.exhibit #container-case,
  #app.exhibit #attract {
    width: 1080px;
    height: 1920px;
    overflow: hidden; }
  #app.exhibit #container-group,
  #app.exhibit #container-group-menu {
    width: 1080px;
    height: 1920px; }
  #app.exhibit #container-group #group #btn-back,
  #app.exhibit #container-group #specimen #btn-back,
  #app.exhibit #container-specimen #group #btn-back,
  #app.exhibit #container-specimen #specimen #btn-back {
    top: 85px;
    left: 35px;
    width: 60px;
    height: 60px; }
  #app.exhibit #container-group-menu #group-menu #btn-close,
  #app.exhibit #container-group-menu #case-menu #btn-close,
  #app.exhibit #container-group-menu #credits #btn-close,
  #app.exhibit #container-case-menu #group-menu #btn-close,
  #app.exhibit #container-case-menu #case-menu #btn-close,
  #app.exhibit #container-case-menu #credits #btn-close,
  #app.exhibit #container-credits #group-menu #btn-close,
  #app.exhibit #container-credits #case-menu #btn-close,
  #app.exhibit #container-credits #credits #btn-close {
    top: 85px;
    right: 35px;
    width: 60px;
    height: 60px; }
