#loader {
  width: 100%;
  height: 100%;
  background-image: url("../img/logo-mark-green.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20vw;
  animation: pulse 3s infinite linear; }
  #loader h1 {
    color: #000;
    font-size: 50px;
    line-height: 50px;
    padding-top: 40px;
    padding-left: 40px;
    font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }
  #loader h2 {
    color: #000;
    font-size: 30px;
    line-height: 30px;
    padding-top: 10px;
    padding-left: 40px;
    font-family: 'W-Book', 'SourceHanSansCN-Normal'; }
  #loader h3 {
    color: #000;
    font-size: 20px;
    line-height: 24px;
    padding-left: 40px;
    font-family: 'W-Medium', 'SourceHanSansCN-Normal'; }

@keyframes pulse {
  0% {
    opacity: 1; }
  20% {
    opacity: 1; }
  50% {
    opacity: .5; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }
