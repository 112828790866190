#case h1 {
  font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }

#case #case-groups-images {
  position: absolute;
  top: 0; }
  #case #case-groups-images img {
    position: absolute;
    z-index: 101;
    pointer-events: none; }

#case #case-groups-buttons {
  position: absolute;
  top: 0; }
  #case #case-groups-buttons div {
    position: absolute;
    z-index: 100;
    background-color: #43C2CC;
    border-radius: 50%;
    display: inline-block; }

#case #case-cta-see-all {
  position: absolute;
  font-family: 'W-Book', 'SourceHanSansCN-Normal';
  text-align: center; }

#app.world #case {
  width: 100%;
  min-height: 100vh;
  height: 100vh; }
  #app.world #case h1 {
    font-size: 6.25vw;
    line-height: 7.5vw;
    padding-top: 13.90625vw;
    padding-left: 5.625vw;
    padding-right: 16.5625vw; }
  #app.world #case #case-groups-images {
    margin-top: 4.6875vw;
    margin-left: 3.125vw;
    width: 100%;
    min-height: 100vh; }
  #app.world #case #case-groups-buttons {
    margin-top: 4.6875vw;
    margin-left: 3.125vw;
    width: 100%;
    min-height: 100vh; }
  #app.world #case #case-cta-see-all {
    bottom: 4.6875vw;
    border-radius: 6.25vw;
    border-width: 0.625vw;
    border-style: solid;
    border-color: #43C2CC;
    font-size: 4.6875vw;
    line-height: 5.625vw;
    padding: 3.125vw;
    width: 56.25vw;
    left: 21.875vw; }
  #app.world #case #case-cta-world-menu {
    display: block;
    position: absolute;
    top: 10.15625vw;
    right: 3.125vw;
    width: 7.8125vw;
    height: 7.8125vw;
    background-image: url("../img/btn-case-menu.svg");
    background-repeat: no-repeat;
    background-size: 100%; }

#app.exhibit #case {
  width: 1080px;
  height: 1920px; }
  #app.exhibit #case h1 {
    font-size: 60px;
    line-height: 72px;
    padding-top: 110px;
    padding-left: 40px;
    padding-right: 70px; }
  #app.exhibit #case #case-groups-images {
    width: 1080px;
    height: 1920px; }
  #app.exhibit #case #case-groups-buttons {
    width: 1080px;
    height: 1920px; }
  #app.exhibit #case #case-cta-see-all {
    bottom: 70px;
    right: 70px;
    border-radius: 40px;
    border: 4px solid #43C2CC;
    font-size: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 400px; }
  #app.exhibit #case #case-cta-world-menu {
    display: none; }
