#app.world #error {
  width: 100%;
  height: 100%; }

#app.exhibit #error {
  width: 1080px;
  height: 1920px; }

#error {
  position: absolute;
  overflow: hidden;
  background-image: url("../img/logo-mark-white.svg");
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20vw; }
