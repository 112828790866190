#specimen #container-slideshow {
  position: absolute;
  overflow: hidden;
  border-bottom: 1px solid #333333; }

#app.world #specimen {
  width: 100%;
  height: 100vh; }
  #app.world #specimen #container-slideshow {
    top: 12.5vw;
    height: 132.8125vw;
    width: 93.75vw;
    margin-left: 3.125vw;
    margin-right: 3.125vw; }
  #app.world #specimen .content-stacked {
    position: absolute;
    top: 147.65625vw;
    width: 93.75vw;
    margin-left: 3.125vw;
    margin-right: 3.125vw; }
    #app.world #specimen .content-stacked .object-info-container {
      padding-bottom: 5.625vw;
      padding-top: 1.5625vw;
      border-bottom: 1px solid #333333; }
      #app.world #specimen .content-stacked .object-info-container .object-number {
        margin-left: 7.8125vw;
        margin-right: 7.8125vw;
        font-family: 'W-Semibold', 'SourceHanSansCN-Medium';
        font-size: 4.375vw;
        line-height: 5.3125vw;
        color: #B3B3B3;
        padding-bottom: 1.5625vw; }
      #app.world #specimen .content-stacked .object-info-container .object-name {
        margin-left: 7.8125vw;
        margin-right: 7.8125vw;
        font-family: 'W-Book', 'SourceHanSansCN-Normal';
        font-size: 7.8125vw;
        line-height: 9.375vw; }
      #app.world #specimen .content-stacked .object-info-container .object-supplemental-name {
        margin-left: 7.8125vw;
        margin-right: 7.8125vw;
        font-family: 'W-Book', 'SourceHanSansCN-Normal';
        font-size: 6.25vw;
        line-height: 7.8125vw; }
      #app.world #specimen .content-stacked .object-info-container .object-info-labels {
        margin-left: 7.8125vw;
        margin-right: 7.8125vw;
        padding-top: 3.125vw; }
        #app.world #specimen .content-stacked .object-info-container .object-info-labels .object-info-label {
          font-family: 'W-Book', 'SourceHanSansCN-Normal';
          font-size: 6.25vw;
          line-height: 7.8125vw; }
      #app.world #specimen .content-stacked .object-info-container .object-media-caption {
        margin-left: 7.8125vw;
        margin-right: 7.8125vw;
        font-family: 'W-Book', 'SourceHanSansCN-Normal';
        font-size: 6.25vw;
        line-height: 7.8125vw; }
    #app.world #specimen .content-stacked #object-nav-home {
      min-height: 53.90625vw;
      width: 46.875vw;
      float: left;
      border-right: 1px solid #333333;
      background-image: url("../img/home-button-bubble.png");
      background-position-x: center;
      background-position-y: 14.0625vw;
      background-repeat: no-repeat;
      background-size: 45%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      padding-bottom: 4.6875vw; }
    #app.world #specimen .content-stacked #object-nav-group {
      min-height: 53.90625vw;
      width: 46.875vw;
      float: right;
      background-position-x: center;
      background-position-y: 4.6875vw;
      background-repeat: no-repeat;
      background-size: 80%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-bottom: 4.6875vw; }
    #app.world #specimen .content-stacked div.object-nav-label {
      padding-top: 46.875vw;
      padding-right: 3.125vw;
      font-family: 'W-Semibold', 'SourceHanSansCN-Medium';
      font-size: 4.0625vw;
      line-height: 5vw;
      margin-left: 3.125vw;
      margin-top: auto;
      text-align: center;
      padding-bottom: 0vw; }
    #app.world #specimen .content-stacked #object-nav-home .object-nav-label {
      margin-top: 0;
      padding-top: 22.1875vw;
      color: #000; }

#app.exhibit #specimen {
  width: 100%;
  height: 100%; }
  #app.exhibit #specimen #container-slideshow {
    top: 85px;
    height: 1440px;
    width: 1020px;
    margin-left: 30px;
    margin-right: 30px; }
  #app.exhibit #specimen .content-stacked {
    position: absolute;
    top: 1525px;
    width: 1020px;
    margin-left: 30px;
    margin-right: 30px; }
    #app.exhibit #specimen .content-stacked .object-info-container {
      position: inherit;
      width: 680px;
      left: 200px;
      min-height: 395px;
      margin-left: -30px;
      padding-bottom: 20px;
      padding-top: 30px; }
      #app.exhibit #specimen .content-stacked .object-info-container .object-number {
        margin-left: 50px;
        margin-right: 50px;
        font-family: 'W-Semibold', 'SourceHanSansCN-Medium';
        font-size: 24px;
        line-height: 28px;
        color: #B3B3B3;
        padding-bottom: 5px; }
      #app.exhibit #specimen .content-stacked .object-info-container .object-name {
        margin-left: 50px;
        margin-right: 50px;
        font-family: 'W-Book', 'SourceHanSansCN-Normal';
        font-size: 50px;
        line-height: 60px; }
      #app.exhibit #specimen .content-stacked .object-info-container .object-supplemental-name {
        margin-left: 50px;
        margin-right: 50px;
        font-family: 'W-Book', 'SourceHanSansCN-Normal';
        font-size: 36px;
        line-height: 44px; }
      #app.exhibit #specimen .content-stacked .object-info-container .object-info-labels {
        margin-left: 50px;
        margin-right: 50px;
        margin-top: 25px; }
        #app.exhibit #specimen .content-stacked .object-info-container .object-info-labels .object-info-label {
          font-family: 'W-Book', 'SourceHanSansCN-Normal';
          font-size: 36px;
          line-height: 44px;
          margin-top: 5px; }
      #app.exhibit #specimen .content-stacked .object-info-container .object-media-caption {
        margin-top: 44px;
        margin-left: 50px;
        margin-right: 50px;
        font-family: 'W-Book', 'SourceHanSansCN-Normal';
        font-size: 36px;
        line-height: 44px; }
    #app.exhibit #specimen .content-stacked #object-nav-home {
      float: left;
      margin-left: -30px;
      min-height: 395px;
      width: 200px;
      border-right: 1px solid #333333;
      background-image: url("../img/home-button-bubble.png");
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center; }
    #app.exhibit #specimen .content-stacked #object-nav-group {
      float: right;
      margin-right: -30px;
      min-height: 395px;
      width: 200px;
      border-left: 1px solid #333333;
      overflow: hidden;
      display: flex;
      flex-direction: column; }
    #app.exhibit #specimen .content-stacked div.object-nav-image {
      width: 160px;
      height: 160px;
      margin-left: 20px;
      margin-top: auto;
      margin-bottom: -20px;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: 100%; }
    #app.exhibit #specimen .content-stacked div.object-nav-label {
      width: 190px;
      padding-right: 10px;
      font-family: 'W-Semibold', 'SourceHanSansCN-Medium';
      font-size: 24px;
      line-height: 29px;
      margin-top: auto;
      margin-bottom: 20px;
      text-align: center;
      margin-left: 10px; }
    #app.exhibit #specimen .content-stacked #object-nav-home .object-nav-label {
      margin-top: 0;
      margin-bottom: 0;
      height: 100%;
      color: #000; }
