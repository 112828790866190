#group {
  position: absolute; }
  #group h1 {
    font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }
  #group #group-objects-mosaic-images {
    position: absolute;
    top: 0;
    border-bottom: 1px solid #333333; }
    #group #group-objects-mosaic-images img {
      position: absolute;
      z-index: 101; }
  #group .content-stacked {
    position: absolute;
    z-index: 101; }
    #group .content-stacked h1 {
      font-family: 'W-Book', 'SourceHanSansCN-Normal'; }
    #group .content-stacked p {
      font-family: 'W-Book', 'SourceHanSansCN-Normal'; }
    #group .content-stacked h2 {
      font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }
    #group .content-stacked .object-list-item {
      border-top: 1px solid #333333; }
      #group .content-stacked .object-list-item h2 {
        font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }
      #group .content-stacked .object-list-item h3 {
        font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }
      #group .content-stacked .object-list-item h4 {
        font-family: 'W-Book', 'SourceHanSansCN-Normal'; }
      #group .content-stacked .object-list-item h5 {
        font-family: 'W-Book', 'SourceHanSansCN-Normal'; }
        #group .content-stacked .object-list-item h5 p {
          padding-top: 0 !important; }

#app.world #group {
  width: 100%;
  height: 100vh; }
  #app.world #group #group-objects-mosaic-images {
    width: 93.75vw;
    height: 85.9375vw;
    margin-left: 4.6875vw;
    margin-right: 3.125vw;
    margin-top: 4.6875vw; }
    #app.world #group #group-objects-mosaic-images img {
      margin-left: -3.125vw; }
  #app.world #group .content-stacked {
    margin-left: 3.125vw;
    margin-right: 3.125vw;
    top: 90.625vw; }
    #app.world #group .content-stacked h1 {
      font-size: 9.375vw;
      line-height: 11.25vw;
      padding-top: 7.8125vw;
      margin-left: 1.5625vw;
      margin-right: 1.5625vw; }
    #app.world #group .content-stacked div#group-description p {
      font-size: 6.25vw;
      line-height: 7.8125vw;
      padding-top: 7.8125vw;
      margin-left: 1.5625vw;
      margin-right: 1.5625vw; }
    #app.world #group .content-stacked h2 {
      display: block;
      background-image: url("../img/double-arrow-down.png");
      background-repeat: no-repeat;
      background-position-x: 1.5625vw;
      background-position-y: 15.625vw;
      background-size: 6.875vw;
      padding-left: 12.5vw;
      font-size: 7.8125vw;
      line-height: 9.375vw;
      padding-top: 15.625vw;
      padding-bottom: 7.8125vw; }
    #app.world #group .content-stacked .object-list-item {
      display: inline-block;
      padding-top: 4.0625vw;
      padding-bottom: 4.0625vw;
      min-height: 31.25vw; }
      #app.world #group .content-stacked .object-list-item img {
        width: 31.25vw;
        height: 31.25vw;
        margin-right: 3.125vw;
        float: left; }
      #app.world #group .content-stacked .object-list-item .object-info-container {
        min-height: 31.25vw;
        width: 56.25vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow-wrap: break-word; }
        #app.world #group .content-stacked .object-list-item .object-info-container h2 {
          width: 56.25vw;
          font-size: 7.8125vw;
          line-height: 9.375vw; }
        #app.world #group .content-stacked .object-list-item .object-info-container h3 {
          width: 56.25vw;
          font-size: 3.75vw;
          line-height: 4.375vw;
          padding-bottom: 1.875vw;
          color: #b3b3b3; }
        #app.world #group .content-stacked .object-list-item .object-info-container h4 {
          width: 56.25vw;
          font-size: 6.25vw;
          line-height: 7.5vw; }
        #app.world #group .content-stacked .object-list-item .object-info-container h5 {
          width: 56.25vw;
          padding-top: 1.875vw; }
          #app.world #group .content-stacked .object-list-item .object-info-container h5 p {
            font-size: 5vw;
            line-height: 5.9375vw;
            margin-left: 0;
            margin-right: 0; }

#app.exhibit #group {
  width: 100%;
  height: 100%; }
  #app.exhibit #group #group-objects-mosaic-images {
    width: 1020px;
    height: 965px;
    margin-left: 30px;
    margin-right: 30px; }
    #app.exhibit #group #group-objects-mosaic-images img {
      margin-left: -30px; }
  #app.exhibit #group .content-stacked {
    top: 965px;
    margin-left: 30px;
    margin-right: 30px; }
    #app.exhibit #group .content-stacked h1 {
      font-size: 70px;
      line-height: 84px;
      padding-top: 74px;
      margin-left: 20px;
      margin-right: 20px; }
    #app.exhibit #group .content-stacked p {
      font-size: 50px;
      line-height: 60px;
      padding-top: 76px;
      margin-left: 20px;
      margin-right: 20px; }
    #app.exhibit #group .content-stacked h2 {
      background-image: url("../img/double-arrow-down.png");
      background-repeat: no-repeat;
      background-position: 20px 130px;
      background-size: 44px;
      display: block;
      padding-left: 90px !important;
      font-size: 50px;
      line-height: 60px;
      padding-top: 130px;
      padding-bottom: 50px; }
    #app.exhibit #group .content-stacked .object-list-item {
      display: inline-block;
      padding-top: 30px;
      padding-bottom: 30px;
      min-height: 252px; }
      #app.exhibit #group .content-stacked .object-list-item img {
        width: 252px;
        height: 252px;
        margin-right: 80px;
        float: left; }
      #app.exhibit #group .content-stacked .object-list-item .object-info-container {
        min-height: 252px;
        width: 688px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow-wrap: break-word; }
        #app.exhibit #group .content-stacked .object-list-item .object-info-container h2 {
          width: 668px;
          font-size: 70px;
          line-height: 84px; }
        #app.exhibit #group .content-stacked .object-list-item .object-info-container h3 {
          width: 668px;
          font-size: 24px;
          line-height: 28px;
          color: #b3b3b3;
          padding-bottom: 20px; }
        #app.exhibit #group .content-stacked .object-list-item .object-info-container h4 {
          width: 668px;
          font-size: 50px;
          line-height: 60px; }
        #app.exhibit #group .content-stacked .object-list-item .object-info-container h5 {
          width: 668px;
          padding-top: 20px; }
          #app.exhibit #group .content-stacked .object-list-item .object-info-container h5 p {
            font-size: 36px;
            line-height: 44px;
            margin-left: 0;
            margin-right: 0; }
