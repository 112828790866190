#group-menu h1 {
  font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }

#group-menu .object-list-item {
  border-top: 1px solid #333333; }
  #group-menu .object-list-item h2 {
    font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }
  #group-menu .object-list-item h3 {
    font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }
  #group-menu .object-list-item h4 {
    font-family: 'W-Book', 'SourceHanSansCN-Normal'; }
  #group-menu .object-list-item h5 {
    font-family: 'W-Book', 'SourceHanSansCN-Normal'; }
    #group-menu .object-list-item h5 p {
      padding-top: 0 !important; }

#app.world #group-menu {
  width: 100%;
  height: 100vh; }
  #app.world #group-menu h1 {
    font-size: 6.25vw;
    line-height: 7.5vw;
    padding-top: 13.90625vw;
    padding-bottom: 6.875vw;
    margin-left: 5.625vw;
    margin-right: 16.5625vw; }
  #app.world #group-menu .content-stacked {
    margin-left: 3.125vw;
    margin-right: 3.125vw;
    top: 28.28125vw; }
    #app.world #group-menu .content-stacked .object-list-item {
      display: inline-block;
      padding-top: 4.0625vw;
      padding-bottom: 4.0625vw;
      min-height: 31.25vw; }
      #app.world #group-menu .content-stacked .object-list-item img {
        width: 31.25vw;
        height: 31.25vw;
        margin-right: 3.125vw;
        float: left; }
      #app.world #group-menu .content-stacked .object-list-item .object-info-container {
        min-height: 31.25vw;
        width: 56.25vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow-wrap: break-word; }
        #app.world #group-menu .content-stacked .object-list-item .object-info-container h2 {
          width: 56.25vw;
          font-size: 7.8125vw;
          line-height: 9.375vw; }
        #app.world #group-menu .content-stacked .object-list-item .object-info-container h3 {
          width: 56.25vw;
          font-size: 3.75vw;
          line-height: 4.375vw;
          padding-bottom: 1.875vw;
          color: #b3b3b3; }
        #app.world #group-menu .content-stacked .object-list-item .object-info-container h4 {
          width: 56.25vw;
          font-size: 6.25vw;
          line-height: 7.5vw; }
        #app.world #group-menu .content-stacked .object-list-item .object-info-container h5 {
          width: 56.25vw;
          padding-top: 1.875vw; }
          #app.world #group-menu .content-stacked .object-list-item .object-info-container h5 p {
            font-size: 5vw;
            line-height: 5.9375vw;
            margin-left: 0;
            margin-right: 0; }

#app.exhibit #group-menu {
  width: 100%;
  height: 100%; }
  #app.exhibit #group-menu h1 {
    font-size: 60px;
    line-height: 72px;
    padding-top: 110px;
    margin-left: 40px; }
  #app.exhibit #group-menu .content-stacked {
    position: absolute;
    margin-left: 30px;
    margin-right: 30px;
    top: 240px; }
    #app.exhibit #group-menu .content-stacked .object-list-item {
      display: inline-block;
      padding-top: 30px;
      padding-bottom: 30px;
      min-height: 252px; }
      #app.exhibit #group-menu .content-stacked .object-list-item img {
        width: 252px;
        height: 252px;
        margin-right: 80px;
        float: left; }
      #app.exhibit #group-menu .content-stacked .object-list-item .object-info-container {
        min-height: 252px;
        width: 688px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow-wrap: break-word; }
        #app.exhibit #group-menu .content-stacked .object-list-item .object-info-container h2 {
          width: 668px;
          font-size: 70px;
          line-height: 84px; }
        #app.exhibit #group-menu .content-stacked .object-list-item .object-info-container h3 {
          width: 668px;
          font-size: 24px;
          line-height: 28px;
          color: #b3b3b3;
          padding-bottom: 20px; }
        #app.exhibit #group-menu .content-stacked .object-list-item .object-info-container h4 {
          width: 668px;
          font-size: 50px;
          line-height: 60px; }
        #app.exhibit #group-menu .content-stacked .object-list-item .object-info-container h5 {
          width: 668px;
          padding-top: 20px; }
          #app.exhibit #group-menu .content-stacked .object-list-item .object-info-container h5 p {
            font-size: 36px;
            line-height: 44px;
            margin-left: 0;
            margin-right: 0; }
