#credits {
  background-color: #000; }
  #credits h1 {
    font-family: 'W-Semibold', 'SourceHanSansCN-Medium'; }

#app.world #credits {
  width: 100%;
  height: 100vh; }
  #app.world #credits h1 {
    font-size: 6.25vw;
    line-height: 7.5vw;
    padding-top: 13.90625vw;
    padding-bottom: 0.9375vw;
    margin-left: 5.625vw;
    margin-right: 5.625vw; }
  #app.world #credits #credits-list {
    margin-left: 5.625vw;
    margin-right: 5.625vw; }
    #app.world #credits #credits-list h2 {
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      padding-top: 8.4375vw;
      padding-bottom: 0vw;
      font-size: 7.8125vw;
      line-height: 9.375vw; }
    #app.world #credits #credits-list h3 {
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      padding-top: 8.4375vw;
      padding-bottom: 0vw;
      font-size: 6.25vw;
      line-height: 7.5vw; }
    #app.world #credits #credits-list div.credit-header {
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      padding-top: 1.5625vw;
      padding-bottom: 0vw;
      font-size: 6.25vw;
      line-height: 7.5vw;
      color: #919191; }
    #app.world #credits #credits-list div.credit-description {
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      padding-bottom: 1.875vw;
      font-size: 5vw;
      line-height: 6.25vw; }

#app.exhibit #credits {
  width: 100%;
  height: 100%; }
  #app.exhibit #credits h1 {
    font-size: 60px;
    line-height: 72px;
    padding-top: 110px;
    padding-left: 40px;
    padding-bottom: 6px; }
  #app.exhibit #credits #credits-list {
    margin-left: 36px;
    margin-right: 36px; }
    #app.exhibit #credits #credits-list h3 {
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      padding-top: 64px;
      padding-bottom: 0;
      font-size: 40px;
      line-height: 48px; }
    #app.exhibit #credits #credits-list div.credit-header {
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      padding-top: 14px;
      padding-bottom: 0px;
      font-size: 40px;
      line-height: 48px;
      color: #919191; }
    #app.exhibit #credits #credits-list div.credit-description {
      font-family: 'W-Book', 'SourceHanSansCN-Normal';
      padding-bottom: 12px;
      font-size: 32px;
      line-height: 40px; }
