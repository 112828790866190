.video .poster-image {
  background-color: #000000; }

.video video,
.video .poster-image {
  position: absolute; }

.video video {
  z-index: 1; }

.video .poster-image {
  z-index: 2; }

#app.world .video .poster-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82.5vw;
  height: 93.75vw; }

#app.world .video .slideshow-slide-video-control {
  position: absolute;
  bottom: 1.78571vh;
  left: 40.625vw; }
  #app.world .video .slideshow-slide-video-control .button-play {
    width: 12.5vw;
    height: 12.5vw;
    background-image: url("../img/play-button.svg");
    background-repeat: no-repeat;
    background-size: 100%; }
  #app.world .video .slideshow-slide-video-control .button-pause {
    display: none;
    width: 12.5vw;
    height: 12.5vw;
    background-image: url("../img/pause-button.svg");
    background-repeat: no-repeat;
    background-size: 100%; }

#app.exhibit .video .poster-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 912px;
  height: 1000px; }

#app.exhibit .video .slideshow-slide-video-control {
  position: absolute;
  bottom: 50px;
  left: 420px; }
  #app.exhibit .video .slideshow-slide-video-control .button-play {
    width: 160px;
    height: 160px;
    background-image: url("../img/play-button.svg");
    background-repeat: no-repeat;
    background-size: 100%; }
  #app.exhibit .video .slideshow-slide-video-control .button-pause {
    display: none;
    width: 160px;
    height: 160px;
    background-image: url("../img/pause-button.svg");
    background-repeat: no-repeat;
    background-size: 100%; }
